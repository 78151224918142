<template>
  <div class="two_date_container">
    <!-- <div class="date_operation">
      <el-checkbox @change="userCheckAll" v-model="isAll">全选</el-checkbox>
      <span>点击日历中的日期将添加到”已选日期“栏</span>
    </div> -->
    <div class="two_date_component">
      <div class="date_list">
        <div class="date_top">
          <div class="date_left">
            <!-- <i @click="setYear('pre')" class="el-icon-d-arrow-left"></i> -->
            <!-- <i @click="setMonth('pre')" class="el-icon-arrow-left"></i> -->
            <div @click="setMonth('pre')" class="date_left_box">
              <SvgIcon
                iconClass="left"
                :style="{
                  fontSize: '14px',
                  cursor: 'pointer',
                  width: '32px',
                  display: dangqianYueBuer ? 'none' : '',
                }"
              ></SvgIcon>
            </div>

            <span
              :style="{
                display: !dangqianYueBuer ? 'none' : 'inline-block',
                width: '5px',
              }"
            ></span>
            <div class="zhonojian">
              <span>{{ oneDate.month }}</span>
              <span>{{ oneDate.year }}</span>
            </div>
            <i></i>
          </div>
          <div class="date_right">
            <div class="zhonojian">
              <span>{{ twoDate.month }}</span>
              <span>{{ twoDate.year }}</span>
            </div>
            <div @click="setMonth('next')" class="date_right_box">
              <SvgIcon
                iconClass="right"
                :style="{
                  fontSize: '14px',
                  cursor: 'pointer',
                  width: '32px',
                  display: xiageYueBuer ? 'none' : '',
                }"
              ></SvgIcon>
            </div>

            <span
              :style="{
                display: !xiageYueBuer ? 'none' : 'inline-block',
                width: '5px',
              }"
            ></span>
            <!-- <i @click="setMonth('next')" class="el-icon-arrow-right"></i> -->
            <!-- <i @click="setYear('oneDa')" class="el-icon-d-arrow-right"></i> -->
          </div>
        </div>
        <div class="date_content">
          <div class="date_content_left">
            <div class="week_title">
              <span v-for="(item, index) in week" :key="index">{{ item }}</span>
            </div>
            <div class="date_list_value">
              <!-- :style="[
                  {
                    display:
                      (index < 8 && list.day > 15 && list.day < 29) ||
                      (index > 15 && list.day < 10)
                        ? 'none'
                        : '',
                  },
                ]" -->
              <button
                :disabled="!list.isSelect"
                @click="selectDay(list, 'one', oneDate)"
                :class="
                  list.isSelect
                    ? list.select
                      ? 'has_select user_select'
                      : 'has_select'
                    : 'no_select'
                "
                :style="[
                  { opacity: list.newisSelect ? '0' : '1' },
                  { cursor: list.newisSelect ? 'unset' : '' },
                ]"
                v-for="(list, index) in oneDate.dateList"
                :key="index"
              >
                {{ list.day }}
              </button>
            </div>
          </div>
          <div class="date_content_right">
            <div class="week_title">
              <span v-for="(item, index) in week" :key="index">{{ item }}</span>
            </div>
            <div class="date_list_value">
              <!-- 
                    :style="[
                  {
                    display:
                      (index < 8 && list.day > 15 && list.day < 29) ||
                      (index > 15 && list.day <= 10) ||
                      list.day > 31
                        ? 'none'
                        : '',
                  },
                ]"
               -->
              <button
                :disabled="!list.isSelect"
                @click="selectDay(list, 'two', twoDate)"
                :class="
                  list.isSelect
                    ? list.select
                      ? 'has_select user_select'
                      : 'has_select'
                    : 'no_select'
                "
                :style="[
                  { opacity: list.newisSelect ? '0' : '1' },
                  { cursor: list.newisSelect ? 'unset' : '' },
                ]"
                v-for="(list, index) in twoDate.dateList"
                :key="index"
              >
                {{ list.day }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="select_date">
        <div class="select_date_title">已选日期</div>
        <div class="select_day_content">
          <div
            class="select_day_list"
            v-for="(list, index) in selectDayList"
            :key="index"
          >
            {{ list }}
            <i @click="closeDay(list, index)" class="el-icon-close"></i>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import SvgIcon from "@/components/SvgIcon.vue";
const week = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
import { get_price } from "@/api/common.js";
export default {
  name: "TwoDateComponent",
  data() {
    return {
      setting: {}, // 最大日期
      dangqianYueBuer: true,
      xiageYueBuer: false,
      isAll: false,
      week: week,
      selectDayList: [],
      oneDate: {
        date: null,
        year: null,
        month: null,
        monthCh: null,
        dateList: [],
      },
      twoDate: {
        date: null,
        year: null,
        month: null,
        monthCh: null,
        dateList: [],
      },
      newNYR: "",
    };
  },
  created() {
    // 当天的后一天
    this.oneDate.date = moment().subtract(-1, "days").format("YYYY-MM-DD");
    // 当天的后一天的后一个月
    this.twoDate.date = moment()
      .subtract(-1, "days")
      .add(1, "month")
      .format("YYYY-MM-DD");

    console.log(this.oneDate.date, this.twoDate.date);
    this.initDateData("gjl");

    this.newNYR = moment().subtract(-1, "days").format("YYYY-MM-DD");
    this.$emit("dateClick", moment().subtract(-1, "days").format("YYYY-MM-DD"));

    // 获取最大日期
    this.getPriceFn();
  },
  methods: {
    getPriceFn() {
      this.setting = JSON.parse(sessionStorage.getItem("setting"));
      console.log("setting", this.setting);
    },
    // 点击日期
    selectDay(data, type, val) {
      // console.log(val.date.split("-"));
      // console.log(data);
      let newNian = val.date.split("-")[0];
      let newYue = val.date.split("-")[1];
      let newDay = data.day > 9 ? data.day : "0" + data.day;

      // console.log(newNian, newYue, newDay);
      this.newNYR = newNian + "-" + newYue + "-" + newDay;
      console.log(this.newNYR);

      this.$emit("dateClick", this.newNYR);

      // let newDate = val.date.split("-")[0]
      this.oneDate.dateList.forEach((item) => {
        item.select = false;
      });
      this.twoDate.dateList.forEach((item) => {
        item.select = false;
      });

      if (data.isSelect) {
        let tempDate = null;
        if (type === "one") {
          tempDate = moment(this.oneDate.date)
            .startOf("month")
            .add(data.day - 1, "day")
            .format("YYYY-MM-DD");
        } else if (type === "two") {
          tempDate = moment(this.twoDate.date)
            .startOf("month")
            .add(data.day - 1, "day")
            .format("YYYY-MM-DD");
        }
        data.select = !data.select;
      }

      if (type === "one") {
        localStorage.setItem("oneDateList", JSON.stringify(this.oneDate));
        localStorage.removeItem("twoDateList");
      } else if (type === "two") {
        localStorage.setItem("twoDateList", JSON.stringify(this.twoDate));
        localStorage.removeItem("oneDateList");
      }
    },
    // 日期排序
    sortData(firstDate, lastDate) {
      return moment(firstDate) - moment(lastDate);
    },

    // 初始化日期数据
    async initDateData(type) {
      let res = await get_price("");
      this.setting = res.data.data[0].setting;
      // this.setting = { max_buy_type: "1", max_buy_value: "2023-02-25" };
      console.log("setting", this.setting);
      sessionStorage.setItem("setting", JSON.stringify(this.setting));
      console.log("几次？", type);

      let val = JSON.parse(localStorage.getItem("oneDateList")) || [];
      let val2 = JSON.parse(localStorage.getItem("twoDateList")) || [];

      console.log("val", val);
      console.log("val2", val2);
      console.log("oneDate", this.oneDate);
      console.log("twoDate", this.twoDate);

      if (type == "next") {
        if (val2.date) {
          if (val2.date == this.oneDate.date) {
            console.log("进了吧", val2.dateList);
            //
            this.oneDate.year = moment(this.oneDate.date).format("YYYY");
            this.oneDate.month = this.disposeMonth(
              moment(this.oneDate.date).month()
            );
            this.oneDate.dateList =
              val2.dateList || this.disposeDateList(this.oneDate.date, "pre");
            this.twoDate.year = moment(this.twoDate.date).format("YYYY");
            this.twoDate.month = this.disposeMonth(
              moment(this.twoDate.date).month()
            );
            this.twoDate.dateList = this.disposeDateList(
              this.twoDate.date,
              "next"
            );
            //
          } else if (val2.date == this.twoDate.date) {
            //
            this.oneDate.year = moment(this.oneDate.date).format("YYYY");
            this.oneDate.month = this.disposeMonth(
              moment(this.oneDate.date).month()
            );
            this.oneDate.dateList = this.disposeDateList(
              this.oneDate.date,
              "pre"
            );
            this.twoDate.year = moment(this.twoDate.date).format("YYYY");
            this.twoDate.month = this.disposeMonth(
              moment(this.twoDate.date).month()
            );
            this.twoDate.dateList =
              val2.dateList || this.disposeDateList(this.twoDate.date, "next");
            //
          } else {
            //
            this.oneDate.year = moment(this.oneDate.date).format("YYYY");
            this.oneDate.month = this.disposeMonth(
              moment(this.oneDate.date).month()
            );
            this.oneDate.dateList = this.disposeDateList(
              this.oneDate.date,
              "pre"
            );
            this.twoDate.year = moment(this.twoDate.date).format("YYYY");
            this.twoDate.month = this.disposeMonth(
              moment(this.twoDate.date).month()
            );
            this.twoDate.dateList = this.disposeDateList(
              this.twoDate.date,
              "next"
            );
            //
          }
        } else if (val.date) {
          if (val.date == this.twoDate.date) {
            //
            this.oneDate.year = moment(this.oneDate.date).format("YYYY");
            this.oneDate.month = this.disposeMonth(
              moment(this.oneDate.date).month()
            );
            this.oneDate.dateList = this.disposeDateList(
              this.oneDate.date,
              "pre"
            );
            this.twoDate.year = moment(this.twoDate.date).format("YYYY");
            this.twoDate.month = this.disposeMonth(
              moment(this.twoDate.date).month()
            );
            this.twoDate.dateList =
              val.dateList || this.disposeDateList(this.twoDate.date, "next");
            //
          } else if (val.date == this.oneDate.date) {
            //
            this.oneDate.year = moment(this.oneDate.date).format("YYYY");
            this.oneDate.month = this.disposeMonth(
              moment(this.oneDate.date).month()
            );
            this.oneDate.dateList =
              val.dateList || this.disposeDateList(this.oneDate.date, "pre");
            this.twoDate.year = moment(this.twoDate.date).format("YYYY");
            this.twoDate.month = this.disposeMonth(
              moment(this.twoDate.date).month()
            );
            this.twoDate.dateList = this.disposeDateList(
              this.twoDate.date,
              "next"
            );
            //
          } else {
            //
            this.oneDate.year = moment(this.oneDate.date).format("YYYY");
            this.oneDate.month = this.disposeMonth(
              moment(this.oneDate.date).month()
            );
            this.oneDate.dateList = this.disposeDateList(
              this.oneDate.date,
              "pre"
            );
            this.twoDate.year = moment(this.twoDate.date).format("YYYY");
            this.twoDate.month = this.disposeMonth(
              moment(this.twoDate.date).month()
            );
            this.twoDate.dateList = this.disposeDateList(
              this.twoDate.date,
              "next"
            );
            //
          }
        }
      } else if (type == "pre") {
        if (val.date) {
          if (val.date == this.twoDate.date) {
            console.log("进了吧", val2.dateList);
            //
            this.oneDate.year = moment(this.oneDate.date).format("YYYY");
            this.oneDate.month = this.disposeMonth(
              moment(this.oneDate.date).month()
            );
            this.oneDate.dateList = this.disposeDateList(
              this.oneDate.date,
              "pre"
            );
            this.twoDate.year = moment(this.twoDate.date).format("YYYY");
            this.twoDate.month = this.disposeMonth(
              moment(this.twoDate.date).month()
            );
            this.twoDate.dateList =
              val.dateList || this.disposeDateList(this.twoDate.date, "next");
            //
          } else if (val.date == this.oneDate.date) {
            //
            this.oneDate.year = moment(this.oneDate.date).format("YYYY");
            this.oneDate.month = this.disposeMonth(
              moment(this.oneDate.date).month()
            );
            this.oneDate.dateList =
              val.dateList || this.disposeDateList(this.oneDate.date, "pre");
            this.twoDate.year = moment(this.twoDate.date).format("YYYY");
            this.twoDate.month = this.disposeMonth(
              moment(this.twoDate.date).month()
            );
            this.twoDate.dateList = this.disposeDateList(
              this.twoDate.date,
              "next"
            );
            //
          } else {
            //
            this.oneDate.year = moment(this.oneDate.date).format("YYYY");
            this.oneDate.month = this.disposeMonth(
              moment(this.oneDate.date).month()
            );
            this.oneDate.dateList = this.disposeDateList(
              this.oneDate.date,
              "pre"
            );
            this.twoDate.year = moment(this.twoDate.date).format("YYYY");
            this.twoDate.month = this.disposeMonth(
              moment(this.twoDate.date).month()
            );
            this.twoDate.dateList = this.disposeDateList(
              this.twoDate.date,
              "next"
            );
            //
          }
        } else if (val2.date) {
          if (val2.date == this.twoDate.date) {
            //
            this.oneDate.year = moment(this.oneDate.date).format("YYYY");
            this.oneDate.month = this.disposeMonth(
              moment(this.oneDate.date).month()
            );
            this.oneDate.dateList = this.disposeDateList(
              this.oneDate.date,
              "pre"
            );
            this.twoDate.year = moment(this.twoDate.date).format("YYYY");
            this.twoDate.month = this.disposeMonth(
              moment(this.twoDate.date).month()
            );
            this.twoDate.dateList =
              val2.dateList || this.disposeDateList(this.twoDate.date, "next");
            //
          } else if (val2.date == this.oneDate.date) {
            //
            this.oneDate.year = moment(this.oneDate.date).format("YYYY");
            this.oneDate.month = this.disposeMonth(
              moment(this.oneDate.date).month()
            );
            this.oneDate.dateList =
              val2.dateList || this.disposeDateList(this.oneDate.date, "pre");
            this.twoDate.year = moment(this.twoDate.date).format("YYYY");
            this.twoDate.month = this.disposeMonth(
              moment(this.twoDate.date).month()
            );
            this.twoDate.dateList = this.disposeDateList(
              this.twoDate.date,
              "next"
            );
            //
          } else {
            //
            this.oneDate.year = moment(this.oneDate.date).format("YYYY");
            this.oneDate.month = this.disposeMonth(
              moment(this.oneDate.date).month()
            );
            this.oneDate.dateList = this.disposeDateList(
              this.oneDate.date,
              "pre"
            );
            this.twoDate.year = moment(this.twoDate.date).format("YYYY");
            this.twoDate.month = this.disposeMonth(
              moment(this.twoDate.date).month()
            );
            this.twoDate.dateList = this.disposeDateList(
              this.twoDate.date,
              "next"
            );
            //
          }
        }
      } else if (type == "gjl") {
        // 刚进来时默认选中当天
        this.oneDate.year = moment(this.oneDate.date).format("YYYY");
        this.oneDate.month = this.disposeMonth(
          moment(this.oneDate.date).month()
        );
        this.oneDate.dateList = this.disposeDateList(this.oneDate.date, "pre");
        this.twoDate.year = moment(this.twoDate.date).format("YYYY");
        this.twoDate.month = this.disposeMonth(
          moment(this.twoDate.date).month()
        );
        this.twoDate.dateList = this.disposeDateList(this.twoDate.date, "next");
        //

        const dangqianYue = this.huoqushijTomorrow().split("-")[1];
        // 本日
        // const dangqianRi = this.huoqushij().split("-")[2];
        // 明日
        const dangqianRi = this.huoqushijTomorrow().split("-")[2];
        // console.log(this.huoqushijTomorrow());
        console.log("刚进来", dangqianYue, dangqianRi);

        if (dangqianYue == this.oneDate.date.split("-")[1]) {
          this.oneDate.dateList.forEach((item) => {
            if (item.day == dangqianRi) {
              item.select = true;
            }
          });

          localStorage.setItem("oneDateList", JSON.stringify(this.oneDate));
          localStorage.removeItem("twoDateList");
        } else if (dangqianYue == this.twoDate.date.split("-")[1]) {
          this.twoDate.dateList.forEach((item) => {
            if (item.day == dangqianRi) {
              item.select = true;
            }
          });

          localStorage.setItem("twoDateList", JSON.stringify(this.twoDate));
          localStorage.removeItem("oneDateList");
        }
      }
    },

    // 上/下月
    setMonth(type) {
      this.isAll = false;
      if (type === "pre") {
        this.oneDate.date = moment(this.oneDate.date)
          .add(-1, "month")
          .format("YYYY-MM-DD");
        this.twoDate.date = moment(this.twoDate.date)
          .add(-1, "month")
          .format("YYYY-MM-DD");
      } else if (type === "next") {
        this.oneDate.date = moment(this.oneDate.date)
          .add(1, "month")
          .format("YYYY-MM-DD");
        this.twoDate.date = moment(this.twoDate.date)
          .add(1, "month")
          .format("YYYY-MM-DD");
      }
      this.initDateData(type);

      // 判断是不是当前月
      const dangqianNian = moment()
        .subtract(-1, "days")
        .format("YYYY-MM-DD")
        .split("-")[0];
      const dangqianYue = moment()
        .subtract(-1, "days")
        .format("YYYY-MM-DD")
        .split("-")[1];
      const xuanzheNian = this.oneDate.date.split("-")[0];
      const xuanzheYue = this.oneDate.date.split("-")[1];
      console.log("当前月", dangqianYue);
      console.log("选择月", xuanzheYue);

      if (dangqianNian == xuanzheNian && dangqianYue == xuanzheYue) {
        this.dangqianYueBuer = true;
      } else {
        this.dangqianYueBuer = false;
      }
    },
    // 获取当前时间
    huoqushij() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },

    // 获取当前时间的第二天
    huoqushijTomorrow() {
      var newDate = Date.parse(new Date()) + 86400000;
      var date = new Date(newDate);
      var seperator1 = "-";

      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();

      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },
    // 删除已选日期
    // closeDay(date, index) {
    //   this.oneDate.dateList.map((item) => {
    //     let tempDate = moment(this.oneDate.date)
    //       .startOf("month")
    //       .add(item.day - 1, "day")
    //       .format("YYYY-MM-DD");
    //     if (tempDate === date) {
    //       item.select = false;
    //     }
    //   });
    //   this.twoDate.dateList.map((item) => {
    //     let tempDate = moment(this.twoDate.date)
    //       .startOf("month")
    //       .add(item.day - 1, "day")
    //       .format("YYYY-MM-DD");
    //     if (tempDate === date) {
    //       item.select = false;
    //     }
    //   });
    //   this.selectDayList.splice(index, 1);
    // },
    // 上/下年
    // setYear(type) {
    //   this.isAll = false;
    //   if (type === "pre") {
    //     this.oneDate.date = moment(this.oneDate.date)
    //       .add(-1, "year")
    //       .format("YYYY-MM-DD");
    //     this.twoDate.date = moment(this.twoDate.date)
    //       .add(-1, "year")
    //       .format("YYYY-MM-DD");
    //   } else if (type === "next") {
    //     this.oneDate.date = moment(this.oneDate.date)
    //       .add(1, "year")
    //       .format("YYYY-MM-DD");
    //     this.twoDate.date = moment(this.twoDate.date)
    //       .add(1, "year")
    //       .format("YYYY-MM-DD");
    //   }
    //   this.initDateData();
    // },
    // 处理每月天数数据格式
    disposeDateList(date, type) {
      let dateList = [];
      let firstWeek = moment(date).startOf("month").format("d");
      let laseWeek = moment(date).endOf("month").format("d");
      if (firstWeek > 0) {
        let preMonthDays = moment(date).add(-1, "month").daysInMonth();
        let temp = preMonthDays - firstWeek;
        for (let i = preMonthDays; i > temp; i--) {
          dateList.unshift({
            day: i,
            isSelect: false,
            select: false,
            newisSelect: true,
          });
        }
      }
      let days = moment(date).daysInMonth();
      for (let i = 1; i <= days; i++) {
        dateList.push({
          day: i,
          isSelect: true,
          select: false,
        });
      }
      if (laseWeek < 5) {
        let temp = 0 - laseWeek;
        for (let i = 1; i <= temp; i++) {
          dateList.push({
            day: i,
            isSelect: false,
            select: false,
          });
        }
      }
      if (dateList.length / 7 < 6) {
        let num = 0 - parseInt(dateList.length / 7);
        if (type === "pre") {
          for (let j = 0; j < num; j++) {
            const tempDays =
              dateList[0].day > 1
                ? dateList[0].day
                : moment(date).add(-1, "month").daysInMonth();
            const temp = tempDays - 6;
            for (let i = tempDays; i >= temp; i--) {
              dateList.unshift({
                day: i,
                isSelect: false,
                select: false,
              });
            }
          }
        } else {
          for (let j = 0; j < num; j++) {
            const temp = dateList[dateList.length - 1].day + 7;
            for (
              let i = dateList[dateList.length - 1].day + 1;
              i <= temp;
              i++
            ) {
              dateList.push({
                day: i,
                isSelect: false,
                select: false,
              });
            }
          }
        }
      }
      dateList.map((item) => {
        this.selectDayList.map((day) => {
          if (
            moment(date)
              .startOf("month")
              .add(item.day - 1, "day")
              .format("YYYY-MM-DD") === day
          ) {
            item.select = true;
          }
        });
      });

      // 今天之前的日期禁止点击 => 改为明天之前的日期禁止点击
      // 60天后的日期
      // console.log(moment(Date.now() + 5097600000).format("YYYY-MM-DD"));
      let newDate60;
      if (this.setting.max_buy_type == 1) {
        newDate60 = this.setting.max_buy_value;
        console.log("newDate60", newDate60);
      } else if (this.setting.max_buy_type == 2) {
        let newSjc = 86400000 * +this.setting.max_buy_value;
        newDate60 = moment(Date.now() + newSjc).format("YYYY-MM-DD");
        console.log("newDate60", newDate60);
      }
      console.log("setting2", this.setting);

      if (type === "pre") {
        // console.log(dateList, date);
        // 获取今天的日期
        // let newDate = moment().format("YYYY-MM-DD");
        // 改为获取明天的时间
        let newDate = this.huoqushijTomorrow();

        console.log(newDate);
        // console.log(date.split("-")[1], newDate.split("-")[1]);
        dateList.forEach((item) => {
          if (
            date.split("-")[0] == newDate.split("-")[0] &&
            date.split("-")[1] == newDate.split("-")[1] &&
            item.day < newDate.split("-")[2]
          ) {
            // console.log("满足了", item.day);
            console.log("判断本月是不是最后一个月，下个月按钮隐藏");
            item.isSelect = false;
          }
        });
        // 判断本月是不是最后一个月，下个月按钮隐藏
        if (
          date.split("-")[1] == newDate60.split("-")[1] ||
          this.newNYR.split("-")[1] == newDate60.split("-")[1]
        ) {
          this.xiageYueBuer = true;
        } else {
          this.xiageYueBuer = false;
        }
        dateList.forEach((item) => {
          // 判断指定天后的日期禁止点击
          if (
            date.split("-")[1] == newDate60.split("-")[1] &&
            item.day > newDate60.split("-")[2]
          ) {
            item.isSelect = false;
          }
        });
      } else {
        console.log(newDate60);
        console.log(dateList, date);

        // 判断本月是不是最后一个月，下个月按钮隐藏
        // console.log(date.split("-")[1], newDate60.split("-")[1]);
        if (
          date.split("-")[0] >= newDate60.split("-")[0] &&
          date.split("-")[1] >= newDate60.split("-")[1]
        ) {
          this.xiageYueBuer = true;
        } else {
          this.xiageYueBuer = false;
        }
        dateList.forEach((item) => {
          // 判断60天后的日期禁止点击
          if (
            date.split("-")[0] == newDate60.split("-")[0] &&
            date.split("-")[1] > newDate60.split("-")[1]
          ) {
            item.isSelect = false;
          } else if (
            date.split("-")[0] >= newDate60.split("-")[0] &&
            date.split("-")[1] >= newDate60.split("-")[1] &&
            item.day > newDate60.split("-")[2]
          ) {
            item.isSelect = false;
          }
        });
      }

      return dateList;
    },
    // 处理月份显示
    disposeMonth(month) {
      let text = "";
      switch (month) {
        case 0:
          text = "January";
          break;
        case 1:
          text = "February";
          break;
        case 2:
          text = "March";
          break;
        case 3:
          text = "April";
          break;
        case 4:
          text = "May";
          break;
        case 5:
          text = "June";
          break;
        case 6:
          text = "July";
          break;
        case 7:
          text = "August";
          break;
        case 8:
          text = "September";
          break;
        case 9:
          text = "October";
          break;
        case 10:
          text = "November";
          break;
        case 11:
          text = "December";
          break;
        default:
          text = "";
          break;
      }
      return text;
    },
  },

  // 用户全选
  // userCheckAll(val) {
  //   let tempBoole = !!val;
  //   this.oneDate.dateList.map((item) => {
  //     if (item.isSelect) {
  //       item.select = tempBoole;
  //       let tempDate = moment(this.oneDate.date)
  //         .startOf("month")
  //         .add(item.day - 1, "day")
  //         .format("YYYY-MM-DD");
  //       let isHave = false; // 用于判断已选日期列表是否存在该日期
  //       this.selectDayList.map((day, index) => {
  //         if (day === tempDate) {
  //           isHave = true;
  //           if (!tempBoole) this.selectDayList.splice(index, 1);
  //         }
  //       });
  //       if (!isHave && tempBoole) this.selectDayList.push(tempDate);
  //     }
  //   });
  //   this.twoDate.dateList.map((item) => {
  //     if (item.isSelect) {
  //       item.select = tempBoole;
  //       let tempDate = moment(this.twoDate.date)
  //         .startOf("month")
  //         .add(item.day - 1, "day")
  //         .format("YYYY-MM-DD");
  //       let isHave = false;
  //       this.selectDayList.map((day, index) => {
  //         if (day === tempDate) {
  //           isHave = true;
  //           if (!tempBoole) this.selectDayList.splice(index, 1);
  //         }
  //       });
  //       if (!isHave && tempBoole) this.selectDayList.push(tempDate);
  //     }
  //   });
  //   this.selectDayList.sort(this.sortData);
  // },
  components: {
    SvgIcon,
  },
};
</script>

<style lang="less" scoped>
.two_date_container {
  //   border: 1px solid rgb(230, 230, 230);
  //   padding: 12px;
  .date_operation {
    span {
      font-size: 12px;
      font-weight: 600;
      margin-left: 20px;
    }
  }
}
.two_date_component {
  //   display: flex;
  //   padding: 20px 8px;
  //   background-color: #f0f2f5;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currentlynot supported by any browser */
  .select_date {
    max-height: 331px;
    overflow-y: auto;
    background-color: #fff;
    border-radius: 6px;
    padding: 0 15px;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: rgba(0, 0, 0, 0.2);
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }
    .select_date_title {
      font-weight: bold;
      padding: 10px 0;
      border-bottom: 1px solid rgb(230, 230, 230);
      margin-bottom: 5px;
    }
    .select_day_content {
      line-height: 34px;
      .select_day_list {
        line-height: 24px;
        display: inline-block;
        margin: 0 auto;
        padding: 0 10px;
        border-radius: 5px;
        background-color: #f0f2f5;
        border: 1px solid rgb(230, 230, 230);
        font-size: 12px;
        i {
          cursor: pointer;
        }
      }
    }
  }
  .no_select {
    color: #b2bac1 !important;
    cursor: no-drop;
    // opacity: 0;
  }
  .has_select {
    cursor: pointer;
  }
  .date_list {
    .date_top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      //   padding: 10px 0;
      padding-bottom: 21px;
      background-color: #fff;
      font-size: 18px;
      color: #12334c;

      .date_left {
        // text-align: left;
        width: 285px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 12px;
        position: relative;
        & > .zhonojian {
          flex: 1;
          text-align: center;
        }
        .date_left_box {
          cursor: pointer;
          position: absolute;
          left: 0;
          height: 32px;
          top: 40%;
          transform: translateY(-45%);
          line-height: 30px;
          &:hover {
            background-color: #eaeef9;
            border-radius: 6px;
          }
        }
      }
      .date_right {
        // text-align: right;
        width: 285px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 12px;
        position: relative;
        & > .zhonojian {
          flex: 1;
          text-align: center;
        }

        .date_right_box {
          cursor: pointer;
          position: absolute;
          right: 0;
          height: 32px;
          top: 40%;
          transform: translateY(-45%);
          line-height: 30px;
          &:hover {
            background-color: #eaeef9;
            border-radius: 6px;
          }
        }
      }
      span {
        font-weight: bold;
        margin: 0 5px;
      }
      i {
        cursor: pointer;
        margin: 0 6px;
      }
    }
    .date_content {
      display: flex;
      // align-items: center;
      justify-content: space-between;
      > * {
        width: 325px;
        background-color: #ffff;
        // border-top-left-radius: 10px;
        // border-top-right-radius: 10px;
        // border-top: 1px solid rgb(230, 230, 230);
        padding: 10px 8px;
      }
      .week_title {
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-bottom: 1px solid #e9ebed;
        padding-bottom: 8px;
        margin-bottom: 10px;
        span {
          color: #12334c;
          font-weight: 400;
          font-size: 14px;
          display: inline-block;
          width: 40px;
          text-align: center;
        }
      }
      .date_list_value {
        font-size: 0;
        line-height: 0;
        button {
          display: inline-block;
          min-width: 44px;
          line-height: 44px;
          text-align: center;
          font-size: 16px;
          border: 0;
          background-color: #fff;
          font-family: regularTtf;
          color: #12334c;
          &.has_select:hover {
            background-color: #eaeef9;
            border-radius: 8px;
          }
        }
      }
    }
  }
  .select_date {
    width: 140px;
    margin-left: 20px;
  }
  .user_select {
    background-color: #12334c !important;
    color: #fff !important;
    border-radius: 8px;
  }
}
</style>
